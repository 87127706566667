import { choice, config } from "../../utils";
import { api } from "../common";

export const bank_account_form_schema = [
  {
    "name": "bank",
    "type": "autocomplete",
    "label": "銀行",
    "required": true,
    "data_source": api.getBankChoices,
  },
  {
    "name": "branch",
    "type": "autocomplete",
    "label": "銀行支店",
    "required": true,
    "data_source": api.getBranchChoices,
    "field_for_init": "branch_no",
  },
  {
    "name": "account_type",
    "type": "choice",
    "label": "預金種類",
    "choices": choice.bank_account_type,
    "required": true,
  },
  {
    "name": "account_number",
    "type": "string",
    "label": "口座番号",
    "max_length": 7,
    "required": true,
  },
  {
    "name": "account_holder",
    "type": "string",
    "label": "口座名義",
    "max_length": 30,
  },
  {
    "name": "image_file",
    "type": "file",
    "label": "キャッシュカードの写し",
    "verbose_name": "image_file_name",
    "handle_download": api.viewAttachment,
    "limit": config.fileSizeLimit,
  },
];
